
import * as Api from "@/types/graphql";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";

@Component({
  components: {},
  watch: {
    selectedServices(val) {
      if (_.isUndefined(this.customList)) {
        this.initServicesList(val);
      }
    },
    customList(val) {
      const temp = [];
      _.mapKeys(_.groupBy(val, "servicetype.des"), (value, key) => {
        temp.push({ header: true, title: key });
        _.forEach(value, (item) => temp.push(item));
      });
      this.items = temp;
    },
  },
})
export default class SelectedServices extends Vue {
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @Mutation removeSelectedService;
  @Mutation resetSelectedServices;

  @Prop()
  customList: Api.Service[];
  @Prop({ default: true })
  enableDelete: boolean;
  @Prop({ default: false })
  enableSelection: boolean;
  @Prop({ default: true })
  showStatus: boolean;

  items = [];
  selected = [];

  initServicesList(services: Api.Service[]) {
    const temp = [];
    _.mapKeys(_.groupBy(services, "servicetype.des"), (value, key) => {
      temp.push({ header: true, title: key });
      _.forEach(value, (item) => temp.push(item));
    });
    this.items = temp;
  }

  mounted() {
    if (_.isUndefined(this.customList)) {
      this.initServicesList(this.selectedServices);
    } else {
      this.initServicesList(this.customList);
    }
  }

  toggleSelected(service){
    this.selected = _.xor(this.selected, [service])
    this.$emit('selectionChanged', this.selected)
  }

  beforeDestroy() {
    this.resetSelectedServices();
  }
}
