
import * as Api from "@/types/graphql";
import _ from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: {},
  watch: {
    selectedServicesState(val, prev) {
      this.selectedServices = val;
    },
    hiddenServices(val, prev) {
      this.initItems();
    },
  },
})
export default class ServicesSelector extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.services)
  servicesState: Api.Service[];
  @State((state: StateStore) => state.data.selectedServices)
  selectedServicesState: Api.Service[];

  @Mutation addSelectedServices;
  @Mutation removeSelectedService;
  @Mutation setSelectedServices;
  @Mutation resetSelectedServices;

  @Prop({ default: "Seleziona servizi" })
  label: string;
  @Prop()
  hiddenServices: Api.Service[];
  @Prop({ default: true })
  multiple: boolean;

  options = [];
  selectedServices = [];
  selected = [];

  async mounted() {
    this.initItems();
  }

  initItems() {
    if (_.isUndefined(this.hiddenServices)) {
      this.initOptions(this.servicesState);
    } else {
      const hiddenGuids = _.map(this.hiddenServices, "guid");
      this.initOptions(
        _.filter(this.servicesState, (service) => {
          return !hiddenGuids.includes(service.guid);
        })
      );
    }
  }

  async initOptions(services: Api.Service[]) {
    _.mapKeys(_.groupBy(services, "servicetype.des"), (value, key) => {
      this.options.push({ header: key });
      _.forEach(value, (item) => this.options.push(item));
    });
  }

  filter(item, queryText) {
    if (item.header || (queryText as string)?.length < 3) return false;

    return item.des
      .toString()
      .toLowerCase()
      .includes(queryText.toString().toLowerCase());
  }

  isSelected(service) {
    return _.findIndex(this.selectedServicesState, service) > -1;
  }

  onChange(services) {
    if (this.multiple == false) {
      const service = _.differenceBy(
        services,
        this.selectedServicesState,
        "guid"
      );
      this.setSelectedServices(service);
      console.log(service);
    } else {
      this.setSelectedServices(
        _.filter(services, (service) => {
          return service instanceof Object;
        })
      );
    }
  }
}
